import React from 'react';
import Content from '../views/Dealership/Price';

export default ({location}) => <Content location={location} seo={{
  title: 'Hvad er prisen for connected car-løsningen til bilforhandlere?',
  lang: 'da-DK',
  description: 'Vil du have en pris på connected car-løsning til bilforhandlere? Udfyld vores korte formular og modtag et tilbud.',
  meta: [{
    name: 'keywords',
    content: 'Connected car løsning  til bilforhandlere'
  }]
}}/>